.App {
}

.App-logo {
  width: 300px;
  height: 50px;
  display: block;
}
.App-logo-xj {
  height: 25px;
}

.App-header-xj {
  color: #000;
  width: 100%;
  height: 30px;
  margin-left: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.App-header-xj a {
  color: inherit;
  text-decoration: none;
}

.App-bg {
  width: 100%;
}
.down-box {
  width: 300px;
  position: absolute;
  top: 50px;
  margin-top: 20px;
}
.down-box-a {
  width: 150px;
  text-align: center;
  display: inline-block;
}
.App-header {
  color: #000;
  width: 100%;
  height: 80px;
  margin-left: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.App-header a {
  color: inherit;
  text-decoration: none;
}

.App-footer {
  background-color: #1d61fd;
  text-align: center;
  color: #fff;
  font-size: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.App-footer a {
  color: inherit;
  text-decoration: none;
}
