@import './hairline.scss';

.box1 {
  margin: 20px 20px;
  position: relative;
  @include hairline(bottom, #000);
  @include hairline-remove(bottom);
}
.box1 h1 {
  text-align: center;
}
